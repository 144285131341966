import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import {base} from './base';
import ReactGA from 'react-ga';
import TriviaBigScreen from './components/main_screens/TriviaBigScreen';

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView() {
  if(process.env.NODE_ENV === "production"){
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }
}

class App extends Component {
  constructor(props) {
        super(props)
        this.state = {
          authenticated: false,
          variables: {},
          loading: true,
        }
    }

  componentDidMount() {
    let langague = "english"
    let splitWindowLocation = window.location.pathname.split('/') || ""
    if(window.location.pathname.split('/').length > 2){
      langague = splitWindowLocation[2]
    } else if(window.location.pathname.split('/').length > 1){
      langague = splitWindowLocation[1]
    }
    this.loadLanguage(langague)
    this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
      context: this,
      state: 'tenantVariables',
      then(variables){
          variables.logOutButtonColor = variables.logOutButtonColor || "#000";
          variables.correctAnswerColor = variables.correctAnswerColor || "transparent";
          variables.inCorrectAnswerColor = variables.inCorrectAnswerColor || "transparent";
          variables.primaryColor = variables.primaryColor || "transparent";
          variables.secondaryColor = variables.secondaryColor || "transparent";
          variables.buttonOutlineColor = variables.buttonOutlineColor || "transparent";
          variables.answerBackgroundColor = variables.answerBackgroundColor || "transparent";
          variables.answerTextColor = variables.answerTextColor || "transparent";
          variables.answerOutlineColor = variables.answerOutlineColor || "transparent";
          document.title = variables.pageTitle || "Prediction";
        this.setState({
            authenticated: false,
            variables: variables,
            loading: false,
        })
      }
    });
  }

  componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

  async loadLanguage(langauge){
        let languagesConstants = null
        if(langauge){
            try {
                languagesConstants = await import("./constants/languages/"+langauge+".js");
            } catch(e) {
                langauge = process.env.REACT_APP_DEFAULT_LANGUAGE;
                languagesConstants = await import("./constants/languages/"+langauge+".js");
            }
        } else {
            langauge = process.env.REACT_APP_DEFAULT_LANGUAGE;
            languagesConstants = await import("./constants/languages/"+langauge+".js");
        }
        this.setState({
            lanaguageConstants: languagesConstants
        })
    }

  render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
    return (
      <div style={{ margin: "0 auto"}}>
        <BrowserRouter onUpdate={logPageView()}>
            <div>
                <div className="main-content">
                    <div className="workspace">
                      <Switch>
                        <Route
                          strict
                          path="/login"
                          render={(props) => {
                            return <Login variables={this.state.variables} stringConstants={this.state.lanaguageConstants} {...props} />
                          }}
                        />
                        <Route
                          exact
                          path="/bigscreen"
                          render={(props) => {
                            return <TriviaBigScreen variables={this.state.variables} {...props} />
                          }}
                        />
                        <Route
                          path="/"
                          render={(props) => {
                            return <MainApp variables={this.state.variables} stringConstants={this.state.lanaguageConstants} {...props} />
                          }}
                        />

                        <Redirect to={`/login`}/>
                      </Switch>
                    </div>
                </div>
            </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;

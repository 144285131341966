import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { unmountComponentAtNode } from "react-dom";
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import Confetti from 'react-confetti';
import appBackgroundImage from '../../styles/images/trivia_background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleSponsorLogo from '../../styles/images/sponsor_logo.png'
import sampleAppTopImage from '../../styles/images/top_icon.png';
import { getDistance } from 'geolib';
import '../../styles/css/react-confirm-alert.css';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';


class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: localStorage.getItem('userEmail') || false,
          alerted: false,
          fullName: '',
          phone: '',
          zip: '',
          tenantRules: {},
          width: 0,
          height: 0,
          question: {},
          questionAnswered: false,
          formFilledOut: false,
          alreadyUpdate: "",
          currentGameId: null,
          currentGameTime: false,
          rotationStarted: false,
          optedIntoEmail: {},
          allowVoting: false,
          currentAnswerId: null,
          locationChecking: true,
          answered: false,
          firstStart: false
        };
        this.logout = this.logout.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element')
      const target = document.getElementById('react-confirm-alert')
      if(target){
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg')
      if(svg){
        svg.parentNode.removeChild(svg)
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount() {
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.logUserActivity(key);
            this.removeReactAlert();
            this.setState({
              currentGameId:key,
            }, ()=> {
              this.currentGameFormFilledRef = base.listenTo('currentGameFormFilled/'+ this.state.currentGameId +'/' +base64EncodedEmail, {
                context: this,
                state: 'formFilledOut',
                then(response){
                  if(response.name){
                    document.getElementById('more-info-form').style.display = "none"
                    document.getElementById('thanks-for-playing-section').style.display = ""
                  }
                }
              })
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      const userEmail = this.state.userEmail;
      const base64EncodedEmail = btoa(userEmail);

      this.gameStartTimeRef = base.bindToState('currentGame/timeStamp', {
        context: this,
        state: 'currentGameTime',
      })

      this.firstStartRef = base.bindToState('currentGame/firstStart', {
        context: this,
        state: 'firstStart',
      })

      this.allowVotingRef = base.bindToState('currentGame/allowVoting', {
        context: this,
        state: 'allowVoting',
      })

      this.answeredRef = base.listenTo('currentGame/answered', {
        context: this,
        state: 'answered',
        then(data){
          if(typeof data === "object"){
            data = false
          }
          if(data){
            this.alertAboutWinningStatus()
          }
          this.setState({
            answered: data
          })
        }
      })

      this.showPercentagesRef = base.bindToState('currentGame/showPercentages', {
        context: this,
        state: 'showPercentages',
      })

      this.questionRef = base.bindToState('currentGame/question', {
        context: this,
        state: 'question'
      })

      this.userAnswersLengthRef = base.bindToState('userAnswers', {
        context: this,
        state: 'userAnswers',
        asArray: true,
      })

      this.currentResponseRef = base.listenTo('userAnswers/'+base64EncodedEmail+'/answerId', {
        context: this,
        state: 'currentResponseId',
        then(answerId){
          if(typeof answerId === "string"){
            this.setState({
              questionAnswered: true,
              currentAnswerId: answerId,
              locationChecking: false
            })
          } else {
            if(this.props.variables && this.props.variables.collectDistance && this.props.variables.formattedAddress && this.props.variables.acceptableDistance){
              this.checkUsersLocation();
            } else {
              this.setState({
                questionAnswered: false,
                locationChecking: false
              })
            }
          }
        }
      })

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    componentWillUnmount() {
      base.removeBinding(this.allowVotingRef);
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.currentResponseRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.questionRef);
      base.removeBinding(this.gameStartTimeRef);
      base.removeBinding(this.showPercentagesRef);
      base.removeBinding(this.firstStartRef);
      if(this.currentGameFormFilledRef){
        base.removeBinding(this.currentGameFormFilledRef);
      }
      base.removeBinding(this.userAnswersLengthRef);
      base.removeBinding(this.answeredRef);
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      localStorage.removeItem('userEmail');
      this.setState({
        userEmail:false
      })
    }

    logUserActivity(currentGame){
      var vm = this;
      if(currentGame){
        var gameid = currentGame;
        var userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameid,
              })
              console.log("user game logged!")
            }
          }
        })
      }
    }

    onClickAnswer(answerClicked, color, primaryColor){
      const gridItem = document.getElementById(answerClicked.id);
      const stringConstants = this.props.stringConstants || {};
      if(typeof this.state.currentGameId === "string" && this.state.currentGameId.length > 0){
          this.logUserActivity(this.state.currentGameId);
      }
      gridItem.style.backgroundColor = primaryColor;
      if(this.state.answered){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.GUESSINGOVERTEXT,
          message: stringConstants.NOMOREPREDICTIONSTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
              onClick: () => gridItem.style.backgroundColor = color
            }
          ]
        })
        return
      }
      if(!this.state.allowVoting){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.PREDICTIONNOTOPENHEADER,
          message: stringConstants.PREDICTIONNOTOPENTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
              onClick: () => gridItem.style.backgroundColor = color
            }
          ]
        })
        return
      }
      confirmAlert({
        variables: this.props.variables,
        title: stringConstants.AREYOUSURETEXT,
        message: stringConstants.NOTABLETOCHANGEYOURVOTETEXT,
        buttons: [
          {
            label: stringConstants.NODISMISSBUTTONTEXT,
            onClick: () => gridItem.style.backgroundColor = color
          },
          {
            label: stringConstants.YESCONFIRMBUTTONTEXT,
            onClick: () => {
              let base64EncodedEmail = btoa(this.state.userEmail);
              let postToUserAnswers = {}
              postToUserAnswers["/userAnswers/" + base64EncodedEmail + "/answerId"] = answerClicked.id;
              postToUserAnswers["/userAnswers/" + base64EncodedEmail + '/timeStamp'] = new Date().getTime().toString();
              appDatabasePrimaryFunctions.ref().update(postToUserAnswers, function(error){
                if(!error){
                  console.log("Answer Clicked");
                  gridItem.style.backgroundColor = "transparent";
                } else {
                  console.log(error)
                  confirmAlert({
                    variables: this.props.variables,
                    title: stringConstants.ERRORTEXT,
                    message: stringConstants.UNKNOWNERRORTEXT,
                    buttons: [
                      {
                        label: stringConstants.OKTEXT,
                      }
                    ]
                  })
                }
              })
            }
          }
        ]
      })
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject("Geolocation is not supported by your browser. Please change browsers to play!");
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                } else {
                  console.log(err.message);
                  reject("An unknown error occurred, check your internet connection and try again");
                }
              }
          );
        }
      })
    }

    getLocationPermission(){
      const locationErrorTitle = "Location Error";
      this.getLocation().then(distance_meters => {
        localStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false
          });
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          confirmAlert({
            title: locationErrorTitle,
            variables: this.props.variables,
            message: "Too far from game area to participate!",
            buttons: [
              {
                label: 'Retry',
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        console.log(error);
        this.setState({
          modal:false,
          loading:false
        });
        localStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: 'Retry',
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const variables = this.props.variables;
      const locationPermissions = localStorage.getItem('locationPermissions');
      if(!this.props.variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage
      const secondaryColor = tenantVariables.secondaryColor || "transparent"
      const primaryColor = tenantVariables.primaryColor || "transparent"
      const logOutButtonColor = tenantVariables.logOutButtonColor

      return(
        <div className="flex-content-container-home">
          <div className="flex-header-home">
            <div className="header-left">
              <img className="topLeftLogo" alt=""/>
            </div>
            <div className="header-right">
              <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.LOGOUTTEXT}</button>
            </div>
          </div>
          <div className="intro-container-home" >
            <div className="hero-text-container">
              <img src={frontLogoImage} className="main-hero-image" alt=""/>
            </div>
            <p style={{display: tenantVariables.playingText ? '' : "none", color:secondaryColor}}>{stringConstants.NEXTGAMESTARTSTEXT} <br/><span className="emphasizedText" style={{backgroundColor:primaryColor, color:secondaryColor}}>{tenantVariables.playingText}</span></p>
          </div>
        </div>
        )
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const question = this.state.question;
      const topLeftImage = tenantVariables.topLeftImage || sampleAppTopImage
      const secondaryColor = tenantVariables.secondaryColor;
      const primaryColor = tenantVariables.primaryColor;
      const answerBackgroundColor = tenantVariables.answerBackgroundColor;
      const answerTextColor = tenantVariables.answerTextColor;
      const answerOutlineColor = tenantVariables.answerOutlineColor;
      const sponsorLogo = tenantVariables.sponsorLogo || sampleSponsorLogo
      const logOutButtonColor = tenantVariables.logOutButtonColor;
      const correctAnswerColor = tenantVariables.correctAnswerColor;
      const inCorrectAnswerColor = tenantVariables.inCorrectAnswerColor;
      const userAnswers = this.state.userAnswers || [];
      const answerList = this.getAnswerList(userAnswers, question.answers || []);
      let showPercentages = false;
      const showPercentagesData = this.state.showPercentages || false;
      const answered = this.state.answered || false;
      if(showPercentagesData === "all"){
        showPercentages = true
      } else if(showPercentagesData === "parttime" && answered){
        showPercentages = true
      }
      return(
        <div className="flex-content-container-home">
          <div className="intro-container-home">
            <div className="flex-header-home" style={{justifyContent: "flex-end"}}>
              <div className="header-right">
                <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.LOGOUTTEXT}</button>
              </div>
            </div>
            <div className="grid-wrapper">
              <div className="creator-awards-logo-playscreen user"><img src={topLeftImage} alt="" style={{marginBottom:"0"}}/></div>
              <div className="card" style={{borderRadius:"20px", marginTop:'0',paddingTop:'0'}}>
                <div className="top-text">
                    <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color:secondaryColor}}>{question.questionText}</p>
                </div>
                <div className="grid-container user" style={{gridAutoFlow:"row", transform:"translateY(0%)"}}>
                  {
                    answerList.map(function(item,i){
                        let answerPercent = parseFloat(((item.count/userAnswers.length) * 100)).toFixed(1).toString() + "%"
                        if(!item.count || userAnswers.length===0){
                          answerPercent = "0%"
                        }
                        if(answerPercent === "100.0%"){
                          answerPercent = "100%"
                        }
                        let buttonColor = answerBackgroundColor;
                        let buttonTextColor = answerTextColor;
                        let buttonOutline = answerOutlineColor;
                        if(item.correct && answered){
                          buttonColor = correctAnswerColor
                          buttonOutline =  correctAnswerColor
                        } else if (item.correct === false && answered){
                          buttonColor = inCorrectAnswerColor
                          buttonOutline =  inCorrectAnswerColor
                        }
                        if(!showPercentages){
                          return <div
                              key={i}
                              id={item.id}
                              className="grid-item"
                              onClick={()=>this.onClickAnswer(item, buttonColor, primaryColor)}
                              style={{backgroundColor: buttonColor, color:buttonTextColor, boxShadow:"unset", borderColor:buttonOutline, display:"inline-flex", alignItems: "center", maxWidth:"380px", minWidth:"300px"}}>
                            <span style={{marginLeft:"auto", marginRight:"auto"}}>{item.answerText}</span>
                          </div>
                        } else {
                          return <div
                              key={i}
                              id={item.id}
                              className="grid-item"
                              onClick={()=>this.onClickAnswer(item, buttonColor, primaryColor)}
                              style={{backgroundColor: buttonColor, color:buttonTextColor, boxShadow:"unset", borderColor:buttonOutline, display:"inline-flex", alignItems: "center", maxWidth:"380px", minWidth:"300px"}}>
                            <div style={{marginRight:"auto", paddingRight: 5, float:"left", textAlign: "left"}}>{item.answerText}</div>
                            <div style={{marginLeft:"auto", paddingLeft: 5, float:"right",textAlign: "right"}}>{answerPercent}</div>
                          </div>
                        }
                    }, this)
                  }
                  <div style={{width:"100%", textAlign: "center"}}>
                    <img src={sponsorLogo} alt="" style={{maxWidth:"380px"}}/>
                  </div>
                </div>
                <div className="mobilehide" style={{height:"100px", width:"1px"}}/>
              </div>
            </div>
          </div>
        </div>
        )
    }

    handleSubmit(event){
      event.preventDefault();
      const stringConstants = this.props.stringConstants || {};
      let zip = this.state.zip.trim();
      let name = this.state.fullName.trim();
      let phone = this.state.phone.trim();
      let timesubmitted = Date.now();
      let receive_emails = this.refs.want_emails.checked;
      if(!this.refs.agree_to_rules_regs.checked){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOAGREERULESANDREGS,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
        }
      if(zip.length === 0 || name.length === 0 || phone === ""){
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.PLEASEFILLOUTTHEFORMTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      let base64EncodedEmail = btoa(this.state.userEmail);
      let formData = {name: name, phone: phone, zip: zip, receive_emails: receive_emails, timeSubmitted: timesubmitted}
      base.post(`currentGameFormFilled/`+ this.state.currentGameId +'/' +base64EncodedEmail, {
        data: formData,
        then(err){
          if(!err){
            document.getElementById('more-info-form').style.display = "none"
            document.getElementById('thanks-for-playing-section').style.display = ""
          } else {
            console.log(err)
            confirmAlert({
                variables: this.props.variables,
                title: stringConstants.ERRORTEXT,
                message: stringConstants.ERRORTEXT,
                buttons: [
                  {
                    label: stringConstants.UNKNOWNERRORTEXT,
                  }
                ]
              })
          }
        }
      });
    }

    getAnswerList(userAnswers, questionAnswers){
      if(userAnswers.length > 0){
        let tempCount = {};
        for(let answerIndex in userAnswers){
          let answerId = userAnswers[answerIndex].answerId;
          let answerText;
          for(let questionsAnswersIndex in questionAnswers){
            if(questionAnswers[questionsAnswersIndex].id === answerId){
              answerText = questionAnswers[questionsAnswersIndex].answerText
            }
          }
          if(tempCount[answerId]){
            tempCount[answerId]['count'] = tempCount[answerId]['count'] + 1;
          } else {
            tempCount[answerId] = {};
            tempCount[answerId]['count'] = 1;
            tempCount[answerId]['answerText'] = answerText;
          }
        }
        for(let add_answer in questionAnswers){
          if(tempCount[questionAnswers[add_answer].id]){
            questionAnswers[add_answer]['count'] = tempCount[questionAnswers[add_answer].id].count
          } else {
            questionAnswers[add_answer]['count'] = 0
          }
        }
      }
      return questionAnswers
    }

    thanksForPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const currentGameTime = this.state.currentGameTime;
      const stringConstants = this.props.stringConstants || {};
      const nowInMilli = Date.now();
      const hoursInMillisecnods24 = 86400000;
      let hideAnswerForm = false
      if(currentGameTime && (currentGameTime + hoursInMillisecnods24) < nowInMilli){
        hideAnswerForm = true
      }
      const question = this.state.question;
      const topLeftImage = tenantVariables.topLeftImage || sampleAppTopImage
      const sponsorLogo = tenantVariables.sponsorLogo || sampleSponsorLogo
      const primaryColor = tenantVariables.primaryColor
      const secondaryColor = tenantVariables.secondaryColor
      const answerBackgroundColor = tenantVariables.answerBackgroundColor
      const answerTextColor = tenantVariables.answerTextColor
      const answerOutlineColor = tenantVariables.answerOutlineColor
      const logOutButtonColor = tenantVariables.logOutButtonColor
      const correctAnswerColor = tenantVariables.correctAnswerColor
      const inCorrectAnswerColor = tenantVariables.inCorrectAnswerColor
      let questionAnswers = question.answers || [];
      let startConfetti = false;
      const userAnswers = this.state.userAnswers || [];
      const totalAnswers = userAnswers.length;
      questionAnswers = this.getAnswerList(userAnswers, questionAnswers);
      for(let answerIndex in question.answers){
        if(question.answers && this.state.currentAnswerId === question.answers[answerIndex].id && question.answers[answerIndex].correct){
          startConfetti = true
        }
      }
      let showPercentages = false;
      const showPercentagesData = this.state.showPercentages || false;
      const answered = this.state.answered || false;
      if(showPercentagesData === "all"){
        showPercentages = true
      } else if(showPercentagesData === "parttime" && answered){
        showPercentages = true
      }
      return(
        <div className="flex-content-container-home">
          <div className="flex-header-home">
            <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{display: startConfetti ? '' : 'none', zIndex: 1}}/>
            <div className="header-left">
              <img className="topLeftLogo" alt=""/>
            </div>
            <div className="header-right" style={{zIndex: 2}}>
              <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.LOGOUTTEXT}</button>
            </div>
          </div>
          <center className="creator-awards-logo-playscreen user"><img src={topLeftImage} alt="" style={{marginBottom:"0"}}/></center>
          <div className="intro-container-home" style={{marginTop:'20px'}}>
            <div className="top-text">
                <p className="top-text-mobile"><span className="emphasizedTextAnswers" style={{backgroundColor:primaryColor, color:secondaryColor}}>{stringConstants.MYPREDICTIONTEXT}</span></p>
            </div>

            <center className="grid-container user" style={{gridAutoFlow:"row", transform:"translateY(0%)"}}>
              {
                questionAnswers.map(function(item,i){
                  if(item.id != this.state.currentAnswerId){
                    return
                  }
                  let answerPercent = parseFloat(((item.count/totalAnswers) * 100)).toFixed(1).toString() + "%"
                  if(!item.count || totalAnswers===0){
                    answerPercent = "0%"
                  }
                  if(answerPercent === "100.0%"){
                    answerPercent = "100%"
                  }
                  let buttonColor = answerBackgroundColor;
                  let buttonTextColor = answerTextColor;
                  let buttonOutline = answerOutlineColor;
                  if(item.correct && answered){
                    buttonColor = correctAnswerColor
                    buttonOutline =  correctAnswerColor
                  } else if (item.correct === false && answered){
                    buttonColor = inCorrectAnswerColor
                    buttonOutline =  inCorrectAnswerColor
                  }
                  if(!showPercentages){
                    return <div
                            key={i}
                            className="endAnswers grid-item"
                            style={{backgroundColor:buttonColor, color:buttonTextColor, boxShadow:"unset", borderColor:buttonOutline, display:"inline-flex", alignItems: "center", maxWidth:"380px", minWidth:"300px"}}>
                            <span style={{marginLeft:"auto", marginRight:"auto"}}>{item.answerText}</span>
                          </div>
                  } else {
                    return <div
                            key={i}
                            className="endAnswers grid-item"
                            style={{backgroundColor:buttonColor, color:buttonTextColor, boxShadow:"unset", borderColor:buttonOutline, display:"inline-flex", alignItems: "center", maxWidth:"380px", minWidth:"300px"}}>
                            <div style={{marginRight:"auto", paddingRight: 5, float:"left", textAlign: "left"}}>{item.answerText}</div>
                            <div style={{marginLeft:"auto", paddingLeft: 5, float:"right",textAlign: "right"}}>{answerPercent}</div>
                          </div>
                  }
                }, this)
              }
              <div className="container-out" id="more-info-form" style={{display: tenantVariables.formHeaderText === "" || hideAnswerForm || !tenantVariables.formHeaderText ? 'none': 'block'}}>
                <div className="question-box question-form" style={{padding:'15px'}}>
                  <h4 style={{fontFamily: "Oswald", fontWeight: 700}}>{tenantVariables.formHeaderText}</h4>
                  <form onSubmit={this.handleSubmit} id="user-values-form">
                    <div className="input-group">
                      <input id="fullName" name="fullName" type="text" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERNAMETEXT} />
                    </div>
                    <div className="input-group">
                      <input id="phone" name="phone" type="tel" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERPHONETEXT} />
                    </div>
                    <div className="input-group">
                      <input id="zip" name="zip" type="text" pattern="[0-9]*" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder={stringConstants.PLACEHOLDERZIPCODETEXT} />
                    </div>
                    <div className="checkbox" style={{padding:'5px'}}>
                      <label className="agree_to_rules_class"><input type="checkbox" ref="agree_to_rules_regs"/> {stringConstants.IAGREETOTEXT} <a target="_blank" href={this.state.tenantRules.rulesAndRegsLink}>{stringConstants.RULESANDRULESTEXT}</a></label>
                    </div>
                    <div className="checkbox" style={{padding:'5px'}}>
                      <label className="want_emails"><input type="checkbox" ref="want_emails" defaultChecked/> {stringConstants.IWANTTORECIEVEEMAILSTEXT} </label>
                    </div>
                    <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>{stringConstants.SUBMITBUTTONTEXT}</strong></button>
                  </form>
                </div>
              </div>
            </center>
            <p id="thanks-for-playing-section" style={{color:secondaryColor, display:"none", marginTop:"5%"}}><span className="thanksForPlayingLittleText">{stringConstants.THANKSFORTEXT}</span><br/><span className="emphasizedText" style={{backgroundColor:primaryColor, color:secondaryColor}}>{stringConstants.PLAYINGTEXT}</span></p>
            <div style={{width:"100%", textAlign: "center"}}>
              <img src={sponsorLogo} alt="" style={{maxWidth:"380px"}}/>
            </div>
          </div>
        </div>
        )
    }

    alertAboutWinningStatus(){
      const userEmail = this.state.userEmail;
      const base64EncodedEmail = btoa(userEmail);
      base.fetch('emailsSent/'+base64EncodedEmail, {
        context: this,
        then(data){
          if(this.state.currentAnswerId){
            const variables = this.props.variables;
            const stringConstants = this.props.stringConstants;
            let alertHeader = variables.winnerPrizeHeader || "You Win!";
            let alertText = variables.winnerPrizeText || "Check your email for a prize!";
            let correctAnswerId;
            for(let answerIndex in this.state.question.answers){
              let answer = this.state.question.answers[answerIndex]
              if(answer.correct){
                correctAnswerId = answer.id
              }
            }
            if(this.state.currentAnswerId === correctAnswerId && !data.name){
              alertHeader = variables.winnerNoPrizeHeader || "You Were Correct!";
              alertText = variables.winnerNoPrizeText || "Unfortunately you did not win a prize this time!";
            } else if(this.state.currentAnswerId !== correctAnswerId){
              alertHeader = variables.missedHeader || "Oh No!";
              alertText = variables.missedText || "Your prediction was incorrect! Come back soon and try again";
            }
            confirmAlert({
              variables: variables,
              title: alertHeader,
              message: alertText,
              buttons: [
                {
                  label: stringConstants.OKTEXT,
                }
              ]
            })
          }
        }
      })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.answered && !this.state.answered){
        this.removeReactAlert()
      }
    }

    render() {
      const tenantVariables = this.props.variables || {};
      if(!this.state.userEmail){
        let redirectString = "/login"
        let langague = ""
        let splitWindowLocation = window.location.pathname.split('/') || ""
        if(window.location.pathname.split('/').length > 1){
          langague = "/" + splitWindowLocation[1]
          redirectString += langague
        }
        return (
            <Redirect to={redirectString} />
        )
      }
      console.log(this.state.locationChecking)
      let renderMainScreen;
      if(!this.state.questionAnswered && this.state.currentGameId && this.state.firstStart && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      } else if(this.state.questionAnswered && this.state.currentGameId && !this.state.locationChecking){
        renderMainScreen = this.thanksForPlayingScreen()
      } else {
        renderMainScreen = this.renderHoldingScreen()
      }

      let backgroundImage = tenantVariables.backgroundImage || appBackgroundImage
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            {renderMainScreen}
          </div>
      )
    }
}

export default Main_App;
